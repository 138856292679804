import { createRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';

import './Emulator.css';
import mobileWriteframe from './assets/images/mobile-transparent.png';

import {
  VertigoVideo,
  VertigoLight,
  VertigoHorizontal,
  vertigoVideoCallback,
} from './Vertigo';
import { SocialCards } from './SocialCards';
import {
  InArticle,
  inArticleCallback,
  InArticleLight,
  inArticleLightCallback,
} from './InArticle';
import { MagicStory } from './MagicStory';
import { 
  Storyboard,
  storyBoardCallback
 } from './Storyboard';

let paragraphsCount = 15;

function MockHeaders() {
  return (
    <div
      className="mock-headers"
      style={{ backgroundColor: 'white', paddingBottom: '2em' }}
    >
      <Row
        className="mb-5 ms-auto me-auto"
        style={{ height: '2em', backgroundColor: 'gray' }}
      ></Row>
      <Row
        className="mb-5 ms-auto me-auto"
        style={{ width: '15em', height: '8em', backgroundColor: 'gray' }}
      ></Row>
      <Row
        className="mb-2 ms-5 me-auto"
        style={{ width: '8em', height: '1em', backgroundColor: 'gray' }}
      ></Row>
      <Row
        className="mb-4 ms-5 me-auto"
        style={{ width: '2em', height: '0.5em', backgroundColor: 'gray' }}
      ></Row>
    </div>
  );
}

function MockText(props) {
  const index = props.index;
  const lines = [];
  const initLine = (
    <Row
      key={`mock-line-${index}-0`}
      className="mock-paragraph-line my-2 me-3"
      style={{ marginLeft: '2em', height: '0.3em', backgroundColor: 'gray' }}
    ></Row>
  );
  lines.push(initLine);
  for (let i = 1; i < 10; i++) {
    const line = (
      <Row
        key={`mock-line-${index}-${i}`}
        className="mock-paragraph-line my-2 ms-3 me-3"
        style={{ height: '0.3em', backgroundColor: 'gray' }}
      ></Row>
    );
    lines.push(line);
  }
  return <div style={{ width: '95%' }}>{lines}</div>;
}

function MockParagraph(props) {
  return (
    <div ref={props.rRef} className="paragraph">
      <MockText index={props.index} />
    </div>
  );
}

function MockPage(props) {
  return (
    <div className="mock-page">
      <MockHeaders />
      <MockMainContent
        variant={props.variant}
        creativeRef={props.creativeRef}
        paragraphRefs={props.paragraphRefs}
      />
    </div>
  );
}

function MockMainContent(props) {
  //should be inside creative components
  let creative, insertCreativeAfterParagraph;
  useEffect(() => {
    switch (props.variant) {
      case 'vertigo':
        vertigoVideoCallback(props.creativeRef);
        break;
      case 'vertigo-light':
        break;
      case 'vertigo-horizontal':
        vertigoVideoCallback(props.creativeRef);
        break;
      case 'social-cards':
        break;
      case 'in-article':
        inArticleCallback(
          props.creativeRef,
          props.paragraphRefs,
          insertCreativeAfterParagraph + 1,
        );
        break;
      case 'in-article-light':
        inArticleLightCallback();
        break;
      case 'magic-story':
        break;
      case 'storyboard':
        storyBoardCallback(
          props.creativeRef,
          props.paragraphRefs,
          insertCreativeAfterParagraph + 1,
        );
        break;
      default:
        //vertigoVideoCallback(props.creativeRef);
        return;
    }
  });
  const paragraphs = [];
  for (let i = 0; i < paragraphsCount; i++) {
    paragraphs.push(
      <MockParagraph
        key={`mock-paragraph-${i}`}
        index={i}
        rRef={props.paragraphRefs[i]}
      />,
    );
  }
  switch (props.variant) {
    case 'vertigo':
      document.title = 'Project Agora Gallery | Previews | Vertigo';
      creative = (
        <VertigoVideo key="creative" creativeRef={props.creativeRef} />
      );
      insertCreativeAfterParagraph = 4;
      break;
    case 'vertigo-light':
      document.title = 'Project Agora Gallery | Previews | Vertigo Light';
      creative = (
        <VertigoLight key="creative" creativeRef={props.creativeRef} />
      );
      insertCreativeAfterParagraph = 4;
      break;
    case 'vertigo-horizontal':
      document.title = 'Project Agora Gallery | Previews | Vertigo Horizontal';
      creative = (
        <VertigoHorizontal key="creative" creativeRef={props.creativeRef} />
      );
      insertCreativeAfterParagraph = 4;
      break;
    case 'social-cards':
      document.title = 'Project Agora Gallery | Previews | Social Cards';
      creative = <SocialCards key="creative" creativeRef={props.creativeRef} />;
      insertCreativeAfterParagraph = 4;
      break;
    case 'in-article':
      document.title = 'Project Agora Gallery | Previews | In Article';
      creative = (
        <InArticle
          key="creative"
          creativeRef={props.creativeRef}
          paragraphRefs={props.paragraphRefs}
        />
      );
      insertCreativeAfterParagraph = 4;
      break;
    case 'in-article-light':
      document.title = 'Project Agora Gallery | Previews | In Article Light';
      creative = (
        <InArticleLight key="creative" creativeRef={props.creativeRef} />
      );
      insertCreativeAfterParagraph = 2;
      break;
    case 'magic-story':
      document.title = 'Project Agora Gallery | Previews | Magic Story';
      creative = <MagicStory key="creative" />;
      insertCreativeAfterParagraph = 4;
      break;
    case 'storyboard':
      document.title = 'Project Agora Gallery | Previews | StoryBoard';
      creative = <Storyboard key="creative" 
      creativeRef={props.creativeRef}
      paragraphRefs={props.paragraphRefs}
      />;
      insertCreativeAfterParagraph = 4;
      break;
    default:
      document.title = 'Project Agora Gallery | Previews | Vertigo';
      creative = (
        <VertigoVideo key="creative" creativeRef={props.creativeRef} />
      );
      insertCreativeAfterParagraph = 4;
  }
  paragraphs.splice(
    insertCreativeAfterParagraph,
    0,
    <div id="creative-top-anchor" key="creative-top-anchor"></div>,
    <div id="creative-mid-anchor" key="creative-mid-anchor"></div>,
    creative,
    <div
      className="creative-placement-holder"
      key="creative-placement-holder"
    ></div>,
    <div id="creative-bottom-anchor" key="creative-bottom-anchor"></div>,
  );
  return <>{paragraphs}</>;
}

export default function Emulator(props) {
  const creativeRef = createRef();
  const previewRef = createRef();

  let creativeDOM = '';
  if (Object.is(props.variant, 'social-cards')) {
    creativeDOM = '#creative-overlay-social-cards';
  } else if (
    Object.is(props.variant, 'in-article') ||
    Object.is(props.variant, 'in-article-light')
  ) {
    creativeDOM = '#creative-overlay-in-article';
  } else if (
    Object.is(props.variant, 'storyboard')
  ) {
    creativeDOM = '#creative-overlay-in-storyboard';
  } else {
    creativeDOM = '#creative-overlay';
  }

  useEffect(() => {
    function mobileScreen() {
      const previewDOM = previewRef.current;
      previewDOM.style.setProperty('margin-top', '0px');
      previewDOM.style.setProperty('height', '100vh');
      previewDOM.style.setProperty('width', '100vw');

      document
        .querySelector('.mobile-wireframe')
        .style.setProperty('display', 'none');
      document
        .querySelector('.creative-placement-holder')
        .style.setProperty('height', '100vh');
      document
        .querySelector('.creative-placement-holder')
        .style.setProperty('width', '100vw');
      document.querySelector('.wrapper').style.setProperty('margin', '0');
    }

    function desktopNoWireframe() {
      const previewDOM = previewRef.current;
      previewDOM.style.setProperty('margin-top', '0px');
      previewDOM.style.setProperty('margin-left', '0px');
      previewDOM.style.setProperty('height', '100vh');
      previewDOM.style.setProperty('width', '100vw');
      document.querySelector('.wrapper').style.setProperty('margin', '0');
      document
        .querySelector('.mobile-wireframe')
        .style.setProperty('display', 'none');
      document
        .querySelector('.creative-placement-holder')
        .style.setProperty('height', '100vh');
      document
        .querySelector('.creative-placement-holder')
        .style.setProperty('width', '100vw');

      document
        .querySelector(creativeDOM)
        .style.setProperty('width', 'calc(100vw - 15px)');
      document.querySelector(creativeDOM).style.setProperty('top', '0');
      if (
        Object.is(props.variant, 'in-article') ||
        Object.is(props.variant, 'in-article-light') ||
        Object.is(props.variant, 'social-cards') || 
        Object.is(props.variant, 'storyboard')
      ) {
        document
          .querySelector(creativeDOM)
          .style.setProperty('height', 'inherit');
      } else {
        document
          .querySelector(creativeDOM)
          .style.setProperty('height', '100vh');
      }
    }

    function desktopWireframe() {
      const previewDOM = previewRef.current;
      const style = getComputedStyle(document.body);

      previewDOM.style.setProperty('margin-top', '77px');
      previewDOM.style.setProperty('margin-left', '32px');
      previewDOM.style.setProperty('margin-bottom', '1px');
      previewDOM.style.setProperty(
        'height',
        style.getPropertyValue('--mobile-preview-height'),
      );
      previewDOM.style.setProperty(
        'width',
        style.getPropertyValue('--mobile-preview-width'),
      );

      document
        .querySelector('.mobile-wireframe')
        .style.setProperty('display', 'inline-block');

      document
        .querySelector('.creative-placement-holder')
        .style.setProperty(
          'height',
          style.getPropertyValue('--mobile-preview-height'),
        );
      document
        .querySelector('.creative-placement-holder')
        .style.setProperty(
          'width',
          style.getPropertyValue('--mobile-preview-width'),
        );
      document.querySelector('.wrapper').style.setProperty('margin', 'auto');

      document.querySelector(creativeDOM).style.setProperty('top', '77px');
      document
        .querySelector(creativeDOM)
        .style.setProperty(
          'width',
          style.getPropertyValue('--creative-overlay-width'),
        );
      document
        .querySelector(creativeDOM)
        .style.setProperty(
          'height',
          style.getPropertyValue('--mobile-preview-height'),
        );
      if (
        Object.is(props.variant, 'in-article') ||
        Object.is(props.variant, 'in-article-light') ||
        Object.is(props.variant, 'social-cards') || 
        Object.is(props.variant, 'storyboard')
      ) {
        document
          .querySelector(creativeDOM)
          .style.setProperty('height', 'inherit');
      }
    }

    // does not detect "Responsive" when inspecting from dev tools
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      mobileScreen();
    }

    window.onresize = (e) => {
      const previewDOM = previewRef.current;
      if (
        window.innerWidth < 750 &&
        !Object.is(previewDOM.style.marginTop, '0px')
      ) {
        desktopNoWireframe();
      } else if (window.innerWidth >= 750) {
        desktopWireframe();
      }
    };
  }, [creativeDOM, previewRef, props.variant]);
  const paragraphRefs = [];
  for (let i = 0; i < paragraphsCount; i++) {
    const paragraphRef = createRef();
    paragraphRefs.push(paragraphRef);
  }

  return (
    <div className="wrapper">
      <div id="wireframe-wrapper">
        {' '}
        <img
          className="mobile-wireframe"
          src={mobileWriteframe}
          alt="wireframe"
        />{' '}
      </div>
      <div className="mobile-preview" ref={previewRef}>
        <MockPage
          variant={props.variant}
          className="mobile-content"
          creativeRef={creativeRef}
          paragraphRefs={paragraphRefs}
        />
      </div>
    </div>
  );
}
