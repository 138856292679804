import { Component, createRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {
  SoundButtonOn,
  SoundButtonOff,
  PlayButton,
  PauseButton,
  CloseButton,
  FullScreen
} from './react-svg';

import storyBoardVideo from './assets/videos/storyboard-video.mp4';
import storyBoardImage_1 from './assets/images/storyboard_1.jpg';
import storyBoardImage_2 from './assets/images/storyboard_2.jpg';
import storyBoardImage_3 from './assets/images/storyboard_3.jpg';
import storyBoardImage_4 from './assets/images/storyboard_4.jpg';
import storyBoardImage_5 from './assets/images/storyboard_5.jpg';
import storyBoard_logo from './assets/images/storyboard_logo.png';

import './Storyboard.css';

export class ButtonBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: props.link,
      text: props.text,
      class: props.class
    };
  }
  render() {
    return (
      <div 
        className={this.state.class}
        onClick={(e) => {
          window.open('https://projectagora.com/', '_blank', 'noopener');
        }}
      >  
        <div
          className="img-btn"
        >
          {' '}
          {this.state.text}
        </div>
    </div>
    );
  }
}

export class Storyboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoRef: props.creativeRef,
      paragraphRefs: props.paragraphRefs,
      videoPlaying: true,
      muted: true,
      percentageDom: createRef(),
      index: 0,
      setIndex: 0
    };
  }

  componentDidMount() {
    const videoDom = this.state.videoRef.current;
    videoDom.onended = () => {
      videoDom.currentTime = 0;
      this.setState({
        videoPlaying: false,
      });
    };
    videoDom.onmouseout = () => {
      this.state.videoRef.current.play();
      this.setState({
        videoPlaying: true,
      });
    };
    videoDom.ontimeupdate = (e) => {
      const percentage = Math.round(
        (videoDom.currentTime * this.state.videoRef.current.offsetWidth) /
          videoDom.duration,
      );
      if (percentage === videoDom.duration) {
        this.handleSelect();
      }
      const dom = this.state.percentageDom.current;
      dom.style.width = `${percentage}px`;
    };

  }

  handleSelect = () => {
    if (this.state.videoPlaying) {
      this.setState({videoPlaying: !this.state.videoPlaying})
      this.state.videoRef.current.pause();
    }
  };

  fullscreen = () => {
    const elem = this.state.videoRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  render() { 
    return (
      <>
        <div id="display-ad-anchor-storyboard"></div>
        <div id="creative-overlay-in-storyboard" className="creative-overlay-in-storyboard-hide" >
          <div className="storyboard-top">
            {' '}
            <a href="https://projectagora.com/" target="_blank" rel="noreferrer">
              {' '}
              Project Agora Advertising{' '}
            </a>{' '}
          </div>
          <div id="storyboard-container">
            <div className="storyboard-header">
              <img src={storyBoard_logo} className="storyboard-logo-img" alt="" />
              <div className="storyboard-title-text"> 
                <b>BMW Series 5</b>
                <div className="storyboard-hastag-text">
                  #5reasonsforsuccess
                </div> 
              </div>
              <div className="storyboard-title-empty-div">
                <div
                  className="svg-button-storyboard"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .querySelector('#creative-overlay-in-storyboard')
                      .remove();
                  }}
                >
                  <CloseButton />
                </div>
              </div>
            </div>
            <div id="storyboard-carousel-container">
              <Carousel wrap={false} onSelect={this.handleSelect}>
                <Carousel.Item interval={20000}>
                  <div className='storyboard-img-container'>
                    <video muted className="storyboard-img-carousel" ref={this.state.videoRef}>
                      <source src={storyBoardVideo} />
                    </video>
                    <div className='video-buttons-storyboard-bottom'>
                      <div className="video-buttons-storyboard-bottom-left">
                        {this.state.videoPlaying ? (
                          <div
                            className="svg-button-storyboard"
                            onClick={(e) => {
                              e.preventDefault();
                              this.state.videoRef.current.pause();
                              this.setState({
                                videoPlaying: false,
                              });
                            }}
                          >
                            <PauseButton />
                          </div>
                        ) : (
                          <div
                            className="svg-button-storyboard"
                            onClick={(e) => {
                              e.preventDefault();
                              this.state.videoRef.current.play();
                              this.setState({
                                videoPlaying: true,
                              });
                            }}
                          >
                            <PlayButton />
                          </div>
                        )}
                      </div>
                      <div className="video-buttons-storyboard-bottom-right">
                        <div className="video-buttons-storyboard-bottom-right-sound">
                          {this.state.muted ? (
                            <div
                              className="svg-button-storyboard"
                              onClick={(e) => {
                                e.preventDefault();
                                const videoDom = this.state.videoRef.current;
                                videoDom.muted = false;
                                this.setState({
                                  muted: false,
                                });
                              }}
                            >
                              <SoundButtonOff />
                            </div>
                          ) : (
                            <div
                              className="svg-button-storyboard"
                              onClick={(e) => {
                                e.preventDefault();
                                const videoDom = this.state.videoRef.current;
                                videoDom.muted = true;
                                this.setState({
                                  muted: true,
                                });
                              }}
                            >
                              <SoundButtonOn />
                            </div>
                          )}
                        </div>
                        <div className="video-buttons-storyboard-bottom-right-fullscreen">
                          <div
                            className="svg-button-storyboard"
                            onClick={(e) => {
                              e.preventDefault();
                              this.fullscreen();
                            }}
                          >
                            <FullScreen />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="video-percentage-storyboard-wrapper">
                      <div className="video-percentage-storyboard" ref={this.state.percentageDom}></div>
                    </div>
                    <ButtonBottom 
                        key="test"
                        link="test-link"
                        text="#1 Be Connected"
                        class="storyboard-below-video-btn"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='storyboard-img-container'>
                    <img className="d-block w-100 storyboard-img-carousel" src={storyBoardImage_1} alt="" />
                    <ButtonBottom 
                      key="test"
                      link="test-link"
                      text="#2 Be Advanced"
                      class="storyboard-below-img-btn"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='storyboard-img-container'>
                    <img className="d-block w-100 storyboard-img-carousel" src={storyBoardImage_2} alt="" />
                    <ButtonBottom 
                      key="test"
                      link="test-link"
                      text="#3 Be Powerful"
                      class="storyboard-below-img-btn"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='storyboard-img-container'>
                    <img className="d-block w-100 storyboard-img-carousel" src={storyBoardImage_3} alt="" />
                    <ButtonBottom 
                      key="test"
                      link="test-link"
                      text="#4 Be Sophisticated"
                      class="storyboard-below-img-btn"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='storyboard-img-container'>
                    <img className="d-block w-100 storyboard-img-carousel" src={storyBoardImage_4} alt="" />
                    <ButtonBottom 
                      key="test"
                      link="test-link"
                      text="#5 Be the ultimate business athlete"
                      class="storyboard-below-img-btn"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='storyboard-img-container'>
                    <img className="d-block w-100 storyboard-img-carousel" src={storyBoardImage_5} alt="" />
                    <ButtonBottom 
                      key="test"
                      link="test-link"
                      text="#Get behind the wheel"
                      class="storyboard-below-img-btn"
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function storyBoardCallback(videoRef, paragraphRefs, afterParagraph) {
  document.querySelector('.creative-placement-holder').style.display = 'none';
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    let displayAnchorVisible = false;
    const displayAdAfterAnchor = new IntersectionObserver(([e]) => {
      displayAnchorVisible = e.intersectionRatio > 0 ? true : false;
      const wrapperDOM = document.querySelector('#creative-overlay-in-storyboard');
      if (displayAnchorVisible && videoRef.current.paused && wrapperDOM) {
        videoRef.current.play();
        wrapperDOM.classList.remove('creative-overlay-in-storyboard-hide');
        wrapperDOM.classList.add('creative-overlay-in-storyboard-show');
      }
      if (displayAnchorVisible && wrapperDOM) {
        videoRef.current.play();
        wrapperDOM.classList.remove('creative-overlay-in-storyboard-hide');
        wrapperDOM.classList.add('creative-overlay-in-storyboard-show');
      }
    });
    displayAdAfterAnchor.observe(paragraphRefs[afterParagraph].current);
  }
}