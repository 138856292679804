import { createRef, Component } from 'react';

import imgMagicStory1 from './assets/images/magic-story-1.jpg';
import imgMagicStory2 from './assets/images/magic-story-2.jpg';
import imgMagicStory3 from './assets/images/magic-story-3.jpg';
import videoMagicStory2 from './assets/videos/magic-story-edited.mp4';
import videoMagicStory1 from './assets/videos/vertigo.mp4';

import './MagicStory.css';

export class ShopNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: props.link,
      left: props.left,
      top: props.top,
      text: props.text,
    };
  }
  render() {
    return (
      <a
        href="https://projectagora.com"
        target="_blank"
        rel="noreferrer"
        className="shop-now-button"
      >
        {' '}
        {this.state.text}{' '}
      </a>
    );
  }
}

export class BuyNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      price: props.price,
      link: props.link,
      left: props.left,
      top: props.top,
      description: props.description,
      clicked: false,
      bnComponents: [],
      id: props.id,
      paused: true
    };
  }

  cacheBNComponents(components) {
    this.setState({ bnComponents: components });
  }

  render() {
    return (
      <div
        className={
          this.state.clicked ? 'buy-now-tooltip-active' : 'buy-now-tooltip'
        }
        style={{ top: this.state.top, left: this.state.left }}
        onClick={() => {
          this.props.pausedCallback(this.state.paused);
          this.setState({ clicked: !this.state.clicked,  paused: !this.state.paused});
          this.state.bnComponents.forEach((component) => {
            if (!Object.is(component.current.state.id, this.state.id))
              component.current.setState({ clicked: false });
          });
        }}
      >
        <span> {this.state.title} </span>
        <div className={this.state.clicked ? 'product-description' : 'hidden'}>
          {' '}
          {this.state.description}{' '}
        </div>
        <div>
          <a
            href="https://projectagora.com/"
            target="_blank"
            rel="noreferrer"
            className={
              this.state.clicked ? 'buy-now-button-clicked' : 'buy-now-button'
            }
          >
            Buy Now for {this.state.price}&euro;
          </a>
        </div>
      </div>
    );
  }
}

export class MagicStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastCreativePlay: new Date().getTime(),
      timeStarted: 0,
      contentState: Symbol('not-started'),
      contentRef: createRef(),
      contentChildren: [],
      paused: false
    };
  }

  handlePausedCallback = (btnPaused) =>{
    this.setState({paused: btnPaused})
  }

  componentDidMount() {
    const contentStates = {
      photo1: Symbol('photo-1'),
      video1: Symbol('video-1'),
      photo2: Symbol('photo-2'),
      video2: Symbol('video-2'),
      photo3: Symbol('photo-3'),
    };
    const stateTimeouts = {
      s1: 5000,
      s2: 11500,
      s3: 16000,
      s4: 22500,
      s5: 27000,
    };
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      let topVisible = false;
      const creativeTopAnchor = document.querySelector('#creative-top-anchor');
      const fitMediaToParent = (dom, id = 'embedded-content') => {
        dom.setAttribute('id', id);
        dom.style.setProperty('height', '100%');
        dom.style.setProperty('width', '100%');
        dom.style.setProperty('overflow', 'hidden');
        dom.style.setProperty('margin', 'auto');
      };
    
      const setDisplay = () => {  
        let timer = 0;
        let percentage = 0;
        if (topVisible === true && this.state.timeStarted === 0) {
          this.setState({
            timeStarted: currentTimestamp(),
            contentState: contentStates.photo1,
          });
          if (document.querySelector('#embedded-content')) {
            this.state.contentRef.current.removeChild(
              document.querySelector('#embedded-content'),
            );
          }
    
          const photo1DOM = document.createElement('img');
          photo1DOM.src = imgMagicStory1;
          const photo1Wrapper = document.createElement('div');
          this.state.contentRef.current.appendChild(photo1Wrapper);
          photo1Wrapper.appendChild(photo1DOM);
    
          fitMediaToParent(photo1Wrapper, 'embedded-content-wrapper');
          fitMediaToParent(photo1DOM);
          const ref1 = createRef(),
          ref2 = createRef(),
          ref3 = createRef();
          const refs = [ref1, ref2, ref3];
          const photoBuyNow1 = (
            <BuyNow
              pausedCallback={this.handlePausedCallback}
              ref={ref1}
              id="bn1"
              description="Satin trench coat"
              key="b1"
              title="TRENCH COAT"
              price="67"
              link="test link"
              top="30%"
              left="40%"
            />
          );
          const photoBuyNow2 = (
            <BuyNow
              pausedCallback={this.handlePausedCallback}
              ref={ref2}
              id="bn2"
              description="Wool oversized cardigan"
              key="b2"
              title="OVERSIZED CARDIGAN"
              price="117"
              link="test link"
              top="55%"
              left="15%"
            />
          );
          const photoBuyNow3 = (
            <BuyNow
              pausedCallback={this.handlePausedCallback}
              ref={ref3}
              id="bn3"
              description="Straight-leg black jeans"
              key="b3"
              title="STRAIGHT-LEG JEANS"
              price="92"
              link="test link"
              top="85%"
              left="30%"
            />
          );
    
          this.setState(
            { contentChildren: [photoBuyNow1, photoBuyNow2, photoBuyNow3] },
              () => {
                refs.forEach((ref) => ref.current.cacheBNComponents(refs));
            },
          );
          const loadinBarWrapperWidth = document.querySelector(
            '.creative-percentage-bar-wrapper',
          ).offsetWidth;
          document.querySelector('.creative-percentage-bar').style.width = '1px';
          const loadingBarFill = setInterval(() => {
            if (!this.state.paused) {
              this.setState({pausePushed: false});
              percentage = (loadinBarWrapperWidth * (timer)  / stateTimeouts.s5);
            }
            document.querySelector('.creative-percentage-bar').style.width = `${Math.round(percentage)}px`;
          },50);

          let intervalTime = setInterval(() => {
            if (!this.state.paused) {
              timer+=50;
              if (timer === stateTimeouts.s1){
                this.setState({ contentChildren: [] });
                this.setState({ contentState: contentStates.video1});
                this.state.contentRef.current.removeChild(
                  document.querySelector('#embedded-content-wrapper')
                );
                const video1DOM = document.createElement('video');
                const video1Source = document.createElement('source');
                video1Source.setAttribute('src', videoMagicStory1);
                video1Source.setAttribute('type', 'video/mp4');
                video1DOM.appendChild(video1Source);
                fitMediaToParent(video1DOM);
                this.state.contentRef.current.appendChild(video1DOM);
                const shopNow = (
                  <ShopNow
                    key="s1"
                    top="15%"
                    left="15%"
                    link="test-link"
                    text="Shop Now"
                  />
                );
                this.setState({ contentChildren: [shopNow] });
                video1DOM.play();
              };
              
              
              if (timer === stateTimeouts.s2){
                this.setState({ contentState: contentStates.photo2 });
                this.state.contentRef.current.removeChild(
                  document.querySelector('#embedded-content'),
                );
                const photo2DOM = document.createElement('img');
                photo2DOM.src = imgMagicStory2;
                fitMediaToParent(photo2DOM);
                this.state.contentRef.current.appendChild(photo2DOM);
    
                const ref1 = createRef(),
                  ref2 = createRef();
                const refs = [ref1, ref2];
    
                const photoBuyNow1 = (
                  <BuyNow
                    pausedCallback={this.handlePausedCallback}
                    ref={ref1}
                    id="1"
                    description="Brown Hat"
                    key="b4"
                    title="HAT"
                    price="67"
                    link="test link"
                    top="25%"
                    left="2%"
                  />
                );
                const photoBuyNow2 = (
                  <BuyNow
                    pausedCallback={this.handlePausedCallback}
                    ref={ref2}
                    id="2"
                    description="Off-white woolen jumper"
                    key="b5"
                    title="WOOL OVERSIZED JUMPER"
                    price="117"
                    link="test link"
                    top="55%"
                    left="15%"
                  />
                );
    
                this.setState(
                  { contentChildren: [photoBuyNow1, photoBuyNow2] },
                  () => {
                    refs.forEach((ref) => ref.current.cacheBNComponents(refs));
                  },
                );
              };
            
              if (timer === stateTimeouts.s3) {
                this.setState({
                  contentState: contentStates.video2,
                });
                this.state.contentRef.current.removeChild(
                  document.querySelector('#embedded-content'),
                );
                const video2DOM = document.createElement('video');
                const video2Source = document.createElement('source');
                video2Source.setAttribute('src', videoMagicStory2);
                video2Source.setAttribute('type', 'video/mp4');
                video2DOM.appendChild(video2Source);
                fitMediaToParent(video2DOM);
                video2DOM.setAttribute('id', 'embedded-content');
                video2DOM.style.objectFit = 'cover';
                video2DOM.style.height = '100%';
                this.setState({ contentChildren: [] });
                this.state.contentRef.current.appendChild(video2DOM);
                video2DOM.play();
                const shopNow = (
                  <ShopNow
                    key="s2"
                    top="15%"
                    left="15%"
                    link="test-link"
                    text="See More"
                  />
                );
                this.setState({ contentChildren: [shopNow] });
              };
            
              if (timer === stateTimeouts.s4){
                this.setState({
                  contentState: contentStates.photo3,
                });
                this.state.contentRef.current.removeChild(
                  document.querySelector('#embedded-content'),
                );
                const photo3DOM = document.createElement('img');
                photo3DOM.src = imgMagicStory3;
                fitMediaToParent(photo3DOM);
                const shopNow = (
                  <ShopNow
                    key="s3"
                    top="15%"
                    left="15%"
                    link="test-link"
                    text="See More"
                  />
                );
                const tooltip = (
                  <div className="photo-3-tooltip">
                    {' '}
                    AW21 Collection Just landed!{' '}
                  </div>
                );
                this.setState({ contentChildren: [tooltip, shopNow] });
                this.state.contentRef.current.appendChild(photo3DOM);
              };
            
              if (timer === stateTimeouts.s5) {
                clearInterval(loadingBarFill);
                clearInterval(intervalTime);
                this.setState({
                  contentState: Symbol('not-started'),
                  timeStarted: 0,
                });
              };
            };
          }, 50);
        };
      };

      const topAnchorObserver = new IntersectionObserver(([e]) => {
        topVisible = e.intersectionRatio > 0 ? true : false;
        setDisplay();
      });
      topAnchorObserver.observe(creativeTopAnchor);
    }
  }

  componentWillUnmount() {
    if (
      this.state.percentageBarInterval &&
      Object.is(this.state.percentageBarInterval, false)
    )
      return clearInterval(this.state.percentageBarInterval);
  }

  render() {
    return (
      <div id="creative-overlay" className="creative-overlay-magic-story">
        <div className="creative-percentage-bar-wrapper">
          <div className="creative-percentage-bar-gaps"> </div>
          <div className="creative-percentage-bar"> </div>
        </div>
        <div ref={this.state.contentRef} className="swapping-content">
          {this.state.contentChildren}
        </div>
      </div>
    );
  }
}

function currentTimestamp() {
  return Math.round(new Date().getTime());
}
