import './App.css';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Emulator from './Emulator';

function App() {
  return (
    <Router>
      {Object.is(window.location.pathname, '/') ? (
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          className="nav"
          id="main-nav"
        >
          <Navbar.Brand as={Link} to="/" className="brand-img">
            <img
              alt=""
              src="/pa_logo_big_no_letters.png"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/vertigo"
                  eventKey="vertigo"
                >
                  Vertigo
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/vertigo-light"
                  eventKey="vertigo-light"
                >
                  Vertigo Light
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/vertigo-horizontal"
                  eventKey="vertigo-horizontal"
                >
                  Vertigo Horizontal
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/social-cards"
                  eventKey="social-cards"
                >
                  Social Cards
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/in-article"
                  eventKey="in-article"
                >
                  In Article
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/in-article-light"
                  eventKey="in-article-light"
                >
                  In Article Light
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/magic-story"
                  eventKey="magic-story"
                >
                  Magic Story
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/storyboard"
                  eventKey="storyboard"
                >
                  StoryBoard
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/feed-arabic.html"
                  eventKey="feed-arabic.html"
                >
                  Arabic Feed
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  as={Link}
                  to="/cube.html"
                  eventKey="cube.html"
                >
                  Cube
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <Switch>
          <Route
            exact
            path="/vertigo"
            render={(props) => <Emulator {...props} variant="vertigo" />}
          ></Route>
          <Route
            exact
            path="/vertigo-light"
            render={(props) => <Emulator {...props} variant="vertigo-light" />}
          ></Route>
          <Route
            exact
            path="/vertigo-horizontal"
            render={(props) => (
              <Emulator {...props} variant="vertigo-horizontal" />
            )}
          ></Route>
          <Route
            exact
            path="/social-cards"
            render={(props) => <Emulator {...props} variant="social-cards" />}
          ></Route>
          <Route
            exact
            path="/in-article"
            render={(props) => <Emulator {...props} variant="in-article" />}
          ></Route>
          <Route
            exact
            path="/in-article-light"
            render={(props) => (
              <Emulator {...props} variant="in-article-light" />
            )}
          ></Route>
          <Route
            exact
            path="/magic-story"
            render={(props) => <Emulator {...props} variant="magic-story" /> }
          ></Route>
          <Route
            exact
            path="/storyboard"
            render={(props) => <Emulator {...props} variant="storyboard" /> }
          ></Route>
          <Route exact path="/feed-arabic.html" onEnter={reload}></Route>
          <Route exact path="/cube.html" onEnter={reload}></Route>
        </Switch>
      )}
    </Router>
  );
}
const reload = () => window.location.reload();
export default App;
