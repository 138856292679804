import { Component } from 'react';

import { SoundButtonOn, SoundButtonOff } from './react-svg';

import vertigoVideo from './assets/videos/vertigo.mp4';
import vertigoHorizontalVideo from './assets/videos/vertigo-horizontal-video.mp4';
import vertigoLightImg from './assets/images/vertigo-light.jpg';
import vertigoHorizontalImg from './assets/images/vertigo-horizontal-hendricks.png';

import './Vertigo.css';

export function vertigoVideoCallback(videoRef) {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    let topVisible = false,
      bottomVisible = false,
      midVisible = false;
    const videoDom = videoRef.current;
    videoDom.onended = function () {
      videoDom.currentTime = 0;
    };
    const creativeTopAnchor = document.querySelector('#creative-top-anchor');
    const creativeBottomAnchor = document.querySelector(
      '#creative-bottom-anchor',
    );
    const setDisplay = () => {
      if (
        topVisible === true ||
        midVisible === true ||
        bottomVisible === true
      ) {
        if (videoDom.paused) {
          videoDom.play();
        }
      }
    };
    const topAnchorObserver = new IntersectionObserver(([e]) => {
      topVisible = e.intersectionRatio > 0 ? true : false;
      setDisplay();
    });
    topAnchorObserver.observe(creativeTopAnchor);

    const creativeMidAnchor = document.querySelector('#creative-mid-anchor');
    const midAnchorObserver = new IntersectionObserver(([e]) => {
      midVisible = e.intersectionRatio > 0 ? true : false;
      setDisplay();
    });
    midAnchorObserver.observe(creativeMidAnchor);

    const bottomAnchorObserver = new IntersectionObserver(([e]) => {
      bottomVisible = e.intersectionRatio > 0 ? true : false;
      setDisplay();
    });
    bottomAnchorObserver.observe(creativeBottomAnchor);
  }
}

export class VertigoVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoRef: props.creativeRef,
      muted: true,
    };
  }

  componentDidMount() {
    const overlayDOM = document.querySelector('#creative-overlay');
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      overlayDOM.style.top = '0px';
      overlayDOM.style.height = '100vh';
      overlayDOM.style.width = '100vw';
    }
  }

  render() {
    return (
      <div id="creative-overlay">
        <video className="vertigo-video" ref={this.state.videoRef}>
          <source src={vertigoVideo} />
        </video>
        <div className="creative-top-overlay">
          {' '}
          Scroll to continue with content{' '}
        </div>
        <div className="creative-bottom-overlay">
          <div className="creative-bottom-buttons-overlay">
            {this.state.muted ? (
              <div
                className="svg-button"
                onClick={(e) => {
                  e.preventDefault();
                  console.log('a');
                  const videoDom = this.state.videoRef.current;
                  videoDom.muted = false;
                  this.setState({
                    muted: false,
                  });
                }}
              >
                <SoundButtonOn />
              </div>
            ) : (
              <div
                className="svg-button"
                onClick={(e) => {
                  console.log('b');
                  e.preventDefault();
                  const videoDom = this.state.videoRef.current;
                  videoDom.muted = true;
                  this.setState({
                    muted: true,
                  });
                }}
              >
                <SoundButtonOff />
              </div>
            )}
            <a
              className="creative-learn-more-button"
              href="https://projectagora.com/"
              rel="noreferrer"
              target="_blank"
            >
              {' '}
              Learn more{' '}
            </a>
          </div>
          <div className="creative-bottom-pa">
            {' '}
            <div> Project Agora Advertising </div>
          </div>
        </div>
      </div>
    );
  }
}

export class VertigoLight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageRef: props.creativeRef,
    };
  }
  render() {
    return (
      <div id="creative-overlay">
        <img
          src={vertigoLightImg}
          className="vertigo-light-image"
          ref={this.state.imageRef}
          alt="vertigo light"
        />
        <div className="creative-top-overlay">
          {' '}
          Scroll to continue with content{' '}
        </div>
        <div className="creative-bottom-overlay-light">
          <div className="creative-bottom-pa">
            {' '}
            <div> Project Agora Advertising </div>
          </div>
        </div>
      </div>
    );
  }
}

// css align image mid => transform: translate(5em, calc(calc(300px / 2) - calc( 200px / 2 ))), only works with emulator
export class VertigoHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoRef: props.creativeRef,
      muted: true,
    };
  }

  componentDidMount() {
    // image position
    /*
    const imageDOM = document.querySelector('.vertigo-horizontal-image')
    const overlayDOM = document.querySelector('#creative-overlay')
    imageDOM.style.left = `${overlayDOM.offsetWidth / 2 - imageDOM.offsetWidth / 2}px` ;
    */
  }

  render() {
    return (
      <div id="creative-overlay" className="creative-overlay-horizontal">
        <img
          src={vertigoHorizontalImg}
          className="vertigo-horizontal-image"
          alt="vertigo horizontal"
        />
        <video
          muted
          className="vertigo-horizontal-video"
          ref={this.state.videoRef}
        >
          <source src={vertigoHorizontalVideo} />
        </video>
        <div className="creative-top-overlay">
          {' '}
          Scroll to continue with content{' '}
        </div>
        <div className="vertigo-horizontal-buttons-top">
          <div className="vertigo-horizontal-buttons-top-right">
          {this.state.muted ? (
              <div
                className="svg-button svg-button-vertigo-horizontal"
                onClick={(e) => {
                  e.preventDefault();
                  const videoDom = this.state.videoRef.current;
                  videoDom.muted = false;
                  this.setState({
                    muted: false,
                  });
                }}
              >
                <SoundButtonOff />
              </div>
            ) : (
              <div
                className="svg-button svg-button-vertigo-horizontal"
                onClick={(e) => {
                  e.preventDefault();
                  const videoDom = this.state.videoRef.current;
                  videoDom.muted = true;
                  this.setState({
                    muted: true,
                  });
                }}
              >
                <SoundButtonOn />
              </div>
            )} 
          </div>
        </div>
        <div className="creative-bottom-overlay">
          <div>
            
            <div
              className="creative-learn-more-button-vertigo-horizontal"
              onClick={(e) => {
                window.open('https://projectagora.com/', '_blank', 'noopener');
              }}
            >
              {' '}
              Learn more
            </div>
          </div>
          <br></br>
          <div className="creative-bottom-pa">
            {' '}
            <div> Project Agora Advertising </div>
          </div>
        </div>
      </div>
    );
  }
}
