export function SoundButtonOn() {
  return (
    <svg
      id="soundOnButton"
      className="soundOnButton"
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
    >
      <g
        id="style"
        className="soundOnButton"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="group1"
          className="soundOnButton"
          transform="translate(-63.000000, -605.000000)"
        >
          <g
            id="Group-10"
            className="soundOnButton"
            transform="translate(64.000000, 606.000000)"
          >
            <circle
              id="Oval"
              className="soundOnButton"
              stroke="#FFFFFF"
              fillOpacity="0.06"
              fill="#000000"
              cx="20"
              cy="20"
              r="20"
            ></circle>
            <g
              id="Page-1"
              className="soundOnButton"
              transform="translate(10.000000, 10.000000)"
              fill="#FFFFFF"
            >
              <g
                id="Group-3"
                className="soundOnButton"
                transform="translate(0.000000, 0.438295)"
                stroke="#FFFFFF"
                strokeWidth="0.3"
              >
                <path
                  d="M5.16826047,11.55 C4.80142326,11.55 4.50263256,11.8712 4.50263256,12.26555 L4.50263256,13.7478 L1.33105116,13.75 L1.33105116,6.82165 L4.50263256,6.82165 L4.50263256,8.3061 C4.50263256,8.70045 4.80142326,9.02165 5.16826047,9.02165 C5.53407442,9.02165 5.83184186,8.70045 5.83184186,8.3061 L5.83184186,6.43775 L11.2034233,1.55265 C11.2648186,1.52625 11.330307,1.55265 11.3533302,1.56695 C11.4208651,1.6082 11.4597488,1.6841 11.4597488,1.77485 L11.4597488,19.23735 C11.4597488,19.3281 11.4208651,19.40345 11.352307,19.44525 C11.3344,19.4568 11.2699349,19.4843 11.2121209,19.46175 L5.83184186,14.1504 L5.83184186,12.26555 C5.83184186,11.8712 5.53407442,11.55 5.16826047,11.55 M12.0071907,0.32175 C11.5380279,0.03575 10.9598884,0.03795 10.4743535,0.35145 C10.4477488,0.37015 10.4216558,0.3905 10.3991442,0.41085 L4.92421395,5.39055 L0.665423256,5.39055 C0.298586047,5.39055 -0.000204651163,5.71175 -0.000204651163,6.1061 L-0.000204651163,14.46555 C-0.000204651163,14.6553 0.0688651163,14.83515 0.198306977,14.97595 C0.326213953,15.1085 0.491981395,15.1811 0.665423256,15.1811 L4.90886512,15.1789 L10.3884,20.59035 C10.4211442,20.61895 10.4538884,20.64535 10.4840744,20.6646 C10.7209581,20.81915 10.990586,20.9 11.2637953,20.9 C11.5231907,20.9 11.7800279,20.82685 12.0066791,20.6888 C12.4973302,20.39235 12.790493,19.85005 12.790493,19.23735 L12.790493,1.77485 C12.790493,1.16325 12.4978419,0.61985 12.0071907,0.32175"
                  id="Fill-1"
                  className="soundOnButton"
                ></path>
              </g>
              <g
                id="Group-6"
                className="soundOnButton"
                transform="translate(15.348837, 2.088295)"
              >
                <path
                  d="M0.787446512,0.518045 C0.573586047,0.440495 0.339772093,0.565345 0.267632558,0.795245 C0.19395814,1.025145 0.309586047,1.274295 0.523446512,1.353495 C3.42949302,2.412795 5.38186512,5.340995 5.38186512,8.636595 C5.38186512,11.928345 3.43358605,14.856545 0.53367907,15.920795 C0.319818605,15.997245 0.20367907,16.248595 0.277865116,16.478495 C0.33567907,16.661095 0.495818605,16.777145 0.66567907,16.777145 C0.70967907,16.777145 0.75367907,16.769445 0.79767907,16.753495 C4.02912093,15.567695 6.20149302,12.306195 6.20149302,8.636595 C6.20149302,4.963145 4.02502791,1.701645 0.787446512,0.518045"
                  id="Fill-4"
                  className="soundOnButton"
                ></path>
              </g>
              <path
                d="M17.8670186,10.722855 C17.8670186,8.095505 16.2262279,5.751405 13.8758093,5.021005 C13.6578558,4.954455 13.4322279,5.087555 13.3703209,5.321855 C13.3063674,5.553955 13.4322279,5.798705 13.6501814,5.865805 C15.6506465,6.488405 17.0473907,8.486555 17.0473907,10.722855 C17.0473907,12.961355 15.6506465,14.960605 13.6501814,15.581555 C13.4322279,15.650305 13.3063674,15.891755 13.3703209,16.126055 C13.4219953,16.319655 13.5841814,16.443955 13.7622279,16.443955 C13.8000884,16.443955 13.8379488,16.437905 13.8758093,16.428005 C16.2262279,15.697605 17.8670186,13.350205 17.8670186,10.722855"
                id="Fill-7"
                className="soundOnButton"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function SoundButtonOff() {
  return (
    <svg
      id="soundOffBtn"
      className="soundOffBtn"
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
    >
      <g
        id="style"
        className="soundOffBtn"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="group1"
          className="soundOffBtn"
          transform="translate(-63.000000, -560.000000)"
        >
          <g
            id="Group-5"
            className="soundOffBtn"
            transform="translate(64.000000, 561.000000)"
          >
            <circle
              id="Oval"
              className="soundOffBtn"
              stroke="#FFFFFF"
              fillOpacity="0.06"
              fill="#000000"
              cx="20"
              cy="20"
              r="20"
            ></circle>
            <g
              id="Page-1"
              className="soundOffBtn"
              transform="translate(12.000000, 8.000000)"
              fill="#FEFEFE"
            >
              <g
                id="Group-3"
                className="soundOffBtn"
                transform="translate(0.000000, 1.666473)"
                stroke="#FFFFFF"
                strokeWidth="0.3"
              >
                <path
                  d="M6.21703636,14.1807273 L6.21703636,12.312 C6.21703636,11.9209091 5.89958182,11.6023636 5.51012727,11.6023636 C5.11903636,11.6023636 4.80049091,11.9209091 4.80049091,12.312 L4.80049091,13.7825455 L1.41921818,13.7841818 L1.41921818,6.91090909 L4.80049091,6.91090909 L4.80049091,8.38527273 C4.80049091,8.77527273 5.11903636,9.09272727 5.51012727,9.09272727 C5.89958182,9.09272727 6.21703636,8.77527273 6.21703636,8.38527273 L6.21703636,6.53236364 L11.9443091,1.68763636 C12.0097636,1.662 12.0795818,1.68818182 12.1035818,1.70181818 C12.1755818,1.74272727 12.2170364,1.81745455 12.2170364,1.908 L12.2170364,4.83661818 L13.6363091,2.33352727 L13.6363091,1.908 C13.6363091,1.30145455 13.3243091,0.762545455 12.8012182,0.466909091 C12.3004909,0.182727273 11.6846727,0.185454545 11.1670364,0.496363636 C11.1386727,0.514909091 11.1103091,0.535090909 11.0863091,0.555272727 L5.25049091,5.49381818 L0.709581818,5.49381818 C0.318490909,5.49381818 -5.45454545e-05,5.81236364 -5.45454545e-05,6.20345455 L-5.45454545e-05,14.4938182 C-5.45454545e-05,14.6825455 0.0730363636,14.8609091 0.211036364,15 C0.3474,15.1309091 0.524672727,15.2034545 0.709581818,15.2034545 L5.23303636,15.2012727 L6.10030909,15.7598182 L6.81812727,14.4938182 L6.21703636,14.1807273 Z"
                  id="Fill-1"
                  className="soundOffBtn"
                ></path>
              </g>
              <path
                d="M12.2173091,20.8926545 C12.2173091,20.9826545 12.1758545,21.0579273 12.1114909,21.0944727 C12.0536727,21.1250182 12.0051273,21.1321091 11.9533091,21.1152 L9,18 L8.28272727,19.2665455 L11.0751273,22.2350182 C11.1084,22.2628364 11.1422182,22.2873818 11.1771273,22.3086545 C11.4296727,22.4613818 11.7171273,22.5415636 12.0084,22.5415636 C12.2849455,22.5415636 12.5587636,22.4695636 12.8004,22.3326545 C13.3234909,22.0386545 13.6365818,21.5002909 13.6365818,20.8926545 L13.6365818,10 L12.2173091,12.5036364 L12.2173091,20.8926545 Z"
                id="Fill-4"
                className="soundOffBtn"
                stroke="#FFFFFF"
                strokeWidth="0.3"
              ></path>
              <g
                id="Group-8"
                className="soundOffBtn"
                transform="translate(3.818182, 0.030109)"
              >
                <path
                  d="M13.0456364,0.515345455 C12.8116364,0.515345455 12.5945455,0.641890909 12.4783636,0.845890909 L0.211636364,22.4840727 C0.125454545,22.6346182 0.103636364,22.8108 0.150545455,22.9782545 C0.196909091,23.1457091 0.306,23.2848 0.456545455,23.3693455 C0.549818182,23.4228 0.649636364,23.4506182 0.755454545,23.4538909 L0.778363636,23.4544364 C1.01181818,23.4544364 1.22890909,23.3284364 1.34454545,23.1249818 L13.6112727,1.48734545 C13.6969091,1.33625455 13.7187273,1.16116364 13.6729091,0.994254545 C13.6265455,0.826254545 13.5174545,0.686072727 13.3658182,0.600436364 C13.2676364,0.5448 13.1569091,0.515345455 13.0456364,0.515345455"
                  id="Fill-6"
                  className="soundOffBtn"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function PlayButton() {
  return (
    <svg
      id="playBtn"
      className="playBtn"
      width="42px"
      height="42px"
      viewBox="0 0 52 52"
      version="1.1"
    >
      <g
        id="Page-1"
        className="playBtn"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="In-artical-magic"
          className="playBtn"
          transform="translate(-75.000000, -1086.000000)"
        >
          <g
            id="play"
            className="playBtn"
            transform="translate(76.000000, 1087.000000)"
          >
            <circle
              id="Oval"
              className="playBtn"
              stroke="#FFFFFF"
              fillOpacity="0.06"
              fill="#000000"
              cx="25"
              cy="25"
              r="25"
            ></circle>
            <path
              d="M21.3899644,31.6210018 L21.3899644,18.3792386 L29.9083366,24.9994364 L21.3899644,31.6210018 Z M34.258791,23.4883192 L20.6446069,12.9104984 C20.0596602,12.4564795 19.2631371,12.3716928 18.5938364,12.6930616 C17.9259186,13.015798 17.5,13.6872537 17.5,14.4229832 L17.5,35.5772571 C17.5,36.3143542 17.9259186,36.9844424 18.5938364,37.3071787 C18.8634927,37.4357262 19.1538917,37.5 19.4415251,37.5 C19.8715923,37.5 20.296128,37.3605123 20.6446069,37.0883744 L34.258791,26.5132887 C34.7248123,26.1467915 35,25.590208 35,24.9994364 C35,24.4100323 34.7248123,23.8534489 34.258791,23.4883192 L34.258791,23.4883192 Z"
              id="Fill-1"
              className="playBtn"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function PauseButton() {
  return (
    <svg
      id="pauseBtn"
      className="pauseBtn"
      width="42px"
      height="42px"
      viewBox="0 0 52 52"
      version="1.1"
    >
      <g
        id="Page-1"
        className="pauseBtn"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="In-artical-magic"
          className="pauseBtn"
          transform="translate(-75.000000, -1016.000000)"
        >
          <g
            id="pause"
            className="pauseBtn"
            transform="translate(76.000000, 1017.000000)"
          >
            <circle
              id="Oval"
              className="pauseBtn"
              stroke="#FFFFFF"
              fillOpacity="0.06"
              fill="#000000"
              cx="25"
              cy="25"
              r="25"
            ></circle>
            <g
              id="Group-3"
              className="pauseBtn"
              transform="translate(18.000000, 14.000000)"
              fill="#FFFFFF"
            >
              <rect
                id="Rectangle-7"
                className="pauseBtn"
                x="0"
                y="0"
                width="5"
                height="22"
                rx="2.5"
              ></rect>
              <rect
                id="Rectangle-7"
                className="pauseBtn"
                x="9"
                y="0"
                width="5"
                height="22"
                rx="2.5"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function CloseButton() {
  return (
    <svg
      id="closeBtn"
      className="CloseBtn"
      width="42px"
      height="42px"
      viewBox="0 0 52 52"
      version="1.1"
    >
      <g
        id="Page-1"
        className="CloseBtn"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="In-artical-magic"
          className="CloseBtn"
          transform="translate(-1881.000000, -110.000000)"
          fill="none"
        >
          <g
            id="Group"
            className="CloseBtn"
            transform="translate(1882.000000, 111.000000)"
          >
            <g id="Group-5" className="CloseBtn">
              <path
                d="M25.0728873,0.207676056 C18.468662,0.207676056 12.2623239,2.77950704 7.59330986,7.44922535 C2.92429577,12.1175352 0.351760563,18.3259859 0.351760563,24.9280986 C0.351760563,31.5302113 2.92429577,37.7365493 7.59190141,42.4062676 C12.2623239,47.0773944 18.468662,49.6478169 25.0728873,49.6478169 C31.675,49.6478169 37.8820423,47.0773944 42.5517606,42.4062676 C47.2214789,37.7365493 49.7919014,31.5302113 49.7919014,24.9280986 C49.7919014,18.3259859 47.2214789,12.1175352 42.5517606,7.44922535 C37.8820423,2.77950704 31.675,0.207676056 25.0728873,0.207676056"
                id="Fill-1"
                className="CloseBtn"
                fillOpacity="0.06"
                fill="#000000"
              ></path>
              <path
                d="M25.0728873,0.207676056 C18.468662,0.207676056 12.2623239,2.77950704 7.59330986,7.44922535 C2.92429577,12.1175352 0.351760563,18.3259859 0.351760563,24.9280986 C0.351760563,31.5302113 2.92429577,37.7365493 7.59190141,42.4062676 C12.2623239,47.0773944 18.468662,49.6478169 25.0728873,49.6478169 C31.675,49.6478169 37.8820423,47.0773944 42.5517606,42.4062676 C47.2214789,37.7365493 49.7919014,31.5302113 49.7919014,24.9280986 C49.7919014,18.3259859 47.2214789,12.1175352 42.5517606,7.44922535 C37.8820423,2.77950704 31.675,0.207676056 25.0728873,0.207676056 Z"
                id="Stroke-3"
                className="CloseBtn"
                stroke="#FFFFFF"
              ></path>
            </g>
            <path
              d="M15.3904139,34.6088993 C15.6506898,34.869159 15.9920353,35 16.3326696,35 C16.6740151,35 17.0153605,34.869159 17.2756365,34.6088993 L25,26.8857275 L32.7243635,34.6088993 C32.9846395,34.869159 33.3259849,35 33.6673304,35 C34.0079647,35 34.3493102,34.869159 34.6095861,34.6088993 C35.130138,34.089091 35.130138,33.2443135 34.6095861,32.723794 L26.8859337,24.9999111 L34.6095861,17.2760282 C35.130138,16.7562198 35.130138,15.9114424 34.6095861,15.3909229 C34.0890343,14.8696924 33.2449154,14.8696924 32.7243635,15.3909229 L25,23.1140947 L17.2756365,15.3909229 C16.7543735,14.8696924 15.9109657,14.8696924 15.3904139,15.3909229 C14.869862,15.9114424 14.869862,16.7562198 15.3904139,17.2760282 L23.1147774,24.9999111 L15.3904139,32.723794 C14.869862,33.2443135 14.869862,34.089091 15.3904139,34.6088993"
              id="Fill-7"
              className="CloseBtn"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FullScreen() {
  return (
    <svg 
      id="fullscreenBtn" 
      className="fullscreenBtn icon" 
      width="42px" 
      height="42px" 
      viewBox="0 0 52 52" 
      version="1.1"
      >
        <g 
          id="Page-1" 
          className="fullscreenBtn" 
          stroke="none" 
          strokeWidth="1" 
          fill="none" 
          fillRule="evenodd"
        >
        <g 
          id="In-artical-magic" 
          className="fullscreenBtn" 
          transform="translate(-1881.000000, -1015.000000)" 
          fill="none"
          >
          <g 
          id="Group" 
          className="fullscreenBtn" 
          transform="translate(1882.000000, 1016.000000)"
          >
            <g 
            id="Group-5" 
            className="fullscreenBtn" 
            transform="translate(0.000000, 0.144437)"
            >
              <path 
                d="M25.0728873,0.207676056 C18.468662,0.207676056 12.2623239,2.77950704 7.59330986,7.44922535 C2.92429577,12.1175352 0.351760563,18.3259859 0.351760563,24.9280986 C0.351760563,31.5302113 2.92429577,37.7365493 7.59190141,42.4062676 C12.2623239,47.0773944 18.468662,49.6478169 25.0728873,49.6478169 C31.675,49.6478169 37.8820423,47.0773944 42.5517606,42.4062676 C47.2214789,37.7365493 49.7919014,31.5302113 49.7919014,24.9280986 C49.7919014,18.3259859 47.2214789,12.1175352 42.5517606,7.44922535 C37.8820423,2.77950704 31.675,0.207676056 25.0728873,0.207676056" 
                id="Fill-1" 
                className="fullscreenBtn" 
                fillOpacity="0.06" 
                fill="#000000">
              </path>
              <path 
              d="M25.0728873,0.207676056 C18.468662,0.207676056 12.2623239,2.77950704 7.59330986,7.44922535 C2.92429577,12.1175352 0.351760563,18.3259859 0.351760563,24.9280986 C0.351760563,31.5302113 2.92429577,37.7365493 7.59190141,42.4062676 C12.2623239,47.0773944 18.468662,49.6478169 25.0728873,49.6478169 C31.675,49.6478169 37.8820423,47.0773944 42.5517606,42.4062676 C47.2214789,37.7365493 49.7919014,31.5302113 49.7919014,24.9280986 C49.7919014,18.3259859 47.2214789,12.1175352 42.5517606,7.44922535 C37.8820423,2.77950704 31.675,0.207676056 25.0728873,0.207676056" 
              id="Stroke-3" 
              className="fullscreenBtn" 
              stroke="#FFFFFF">
              </path>
            </g>
            <g 
              id="Group-14" 
              className="fullscreenBtn" 
              transform="translate(13.380282, 14.228944)" 
              fill="#FFFFFF"
              >
              <path 
              d="M0.756478873,7.82077465 C1.16352113,7.82077465 1.49450704,7.49049296 1.49450704,7.08274648 L1.49450704,2.95950704 L7.98464789,9.44964789 C8.12901408,9.59471831 8.3184507,9.6665493 8.5071831,9.6665493 C8.69591549,9.6665493 8.88464789,9.59471831 9.02901408,9.44964789 C9.31704225,9.16161972 9.31704225,8.69401408 9.02901408,8.40669014 L2.53887324,1.91584507 L6.66211268,1.91584507 C7.06915493,1.91584507 7.39943662,1.58556338 7.39943662,1.17711268 C7.39943662,0.769366197 7.06915493,0.439788732 6.66211268,0.439788732 L0.756478873,0.439788732 C0.348732394,0.439788732 0.0184507042,0.769366197 0.0184507042,1.17711268 L0.0184507042,7.08274648 C0.0184507042,7.49049296 0.348732394,7.82077465 0.756478873,7.82077465" id="Fill-6" className="fullscreenBtn"></path>
              <path 
              d="M0.756478873,21.106831 L6.66211268,21.106831 C7.06915493,21.106831 7.39943662,20.7765493 7.39943662,20.369507 C7.39943662,19.9617606 7.06915493,19.6307746 6.66211268,19.6307746 L2.53887324,19.6307746 L9.02901408,13.1406338 C9.31704225,12.8519014 9.31704225,12.385 9.02901408,12.0962676 C8.74098592,11.8082394 8.27338028,11.8082394 7.98464789,12.0962676 L1.49450704,18.5871127 L1.49450704,14.4638732 C1.49450704,14.056831 1.16352113,13.7258451 0.756478873,13.7258451 C0.348732394,13.7258451 0.0184507042,14.056831 0.0184507042,14.4638732 L0.0184507042,20.369507 C0.0184507042,20.7765493 0.348732394,21.106831 0.756478873,21.106831" id="Fill-8" className="fullscreenBtn"></path><path d="M22.8997183,13.7258451 C22.4926761,13.7258451 22.1623944,14.056831 22.1623944,14.4638732 L22.1623944,18.5871127 L15.6715493,12.0962676 C15.3835211,11.8082394 14.9159155,11.8082394 14.6285915,12.0962676 C14.3398592,12.385 14.3398592,12.8519014 14.6285915,13.1406338 L21.1187324,19.6307746 L16.9961972,19.6307746 C16.5877465,19.6307746 16.2574648,19.9617606 16.2574648,20.369507 C16.2574648,20.7765493 16.5877465,21.106831 16.9961972,21.106831 L22.9011268,21.106831 C23.308169,21.106831 23.6384507,20.7765493 23.6384507,20.369507 L23.6384507,14.4638732 C23.6384507,14.056831 23.308169,13.7258451 22.8997183,13.7258451" id="Fill-10" className="fullscreenBtn"></path>
              <path 
              d="M22.9010563,0.43943662 L16.9961268,0.43943662 C16.5876761,0.43943662 16.2573944,0.76971831 16.2573944,1.17746479 C16.2573944,1.58521127 16.5876761,1.91619718 16.9961268,1.91619718 L21.118662,1.91619718 L14.6285211,8.40633803 C14.3397887,8.6943662 14.3397887,9.16126761 14.6285211,9.45 C14.7721831,9.5943662 14.9616197,9.66619718 15.1503521,9.66619718 C15.3383803,9.66619718 15.5278169,9.5943662 15.6714789,9.45 L22.1623239,2.95985915 L22.1623239,7.08309859 C22.1623239,7.49084507 22.4926056,7.82042254 22.8996479,7.82042254 C23.3080986,7.82042254 23.6383803,7.49084507 23.6383803,7.08309859 L23.6383803,1.17746479 C23.6383803,0.76971831 23.3080986,0.43943662 22.9010563,0.43943662" id="Fill-12" className="fullscreenBtn"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}