import { Component, createRef } from 'react';
import {
  SoundButtonOn,
  SoundButtonOff,
  PlayButton,
  PauseButton,
  CloseButton,
} from './react-svg';

import inArticleVideo from './assets/videos/in-article.mp4';
import inArticleLightImage from './assets/images/in-article-light-image.png';

import './InArticle.css';

export class InArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoRef: props.creativeRef,
      paragraphRefs: props.paragraphRefs,
      muted: true,
      videoPlaying: true,
      percentageDom: createRef(),
    };
  }

  componentDidMount() {
    const videoDom = this.state.videoRef.current;
    videoDom.onended = () => {
      videoDom.currentTime = 0;
      this.setState({
        videoPlaying: false,
      });
    };
    videoDom.onmouseout = () => {
      this.state.videoRef.current.play();
      this.setState({
        videoPlaying: true,
      });
    };
    videoDom.ontimeupdate = (e) => {
      const percentage = Math.round(
        (videoDom.currentTime * this.state.videoRef.current.offsetWidth) /
          videoDom.duration,
      );
      const dom = this.state.percentageDom.current;
      dom.style.width = `${percentage}px`;
    };
  }

  render() {
    return (
      <>
        <div id="display-ad-anchor"> </div>
        <div
          id="creative-overlay-in-article"
          className="creative-overlay-in-article-hide"
        >
          <video muted className="in-article-video" ref={this.state.videoRef}>
            <source src={inArticleVideo} />
          </video>
          <div className="video-buttons-top">
            <div className="video-buttons-top-right">
              <div
                className="svg-button"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector('#creative-overlay-in-article')
                    .remove();
                }}
              >
                <CloseButton />
              </div>
            </div>
          </div>
          <div className="video-buttons-bottom">
            <div className="video-buttoms-bottom-left">
              {this.state.videoPlaying ? (
                <div
                  className="svg-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.state.videoRef.current.pause();
                    this.setState({
                      videoPlaying: false,
                    });
                  }}
                >
                  <PauseButton />
                </div>
              ) : (
                <div
                  className="svg-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.state.videoRef.current.play();
                    this.setState({
                      videoPlaying: true,
                    });
                  }}
                >
                  <PlayButton />
                </div>
              )}
            </div>
            <div className="video-buttons-bottom-right">
              {this.state.muted ? (
                <div
                  className="svg-button"
                  onClick={(e) => {
                    e.preventDefault();
                    const videoDom = this.state.videoRef.current;
                    videoDom.muted = false;
                    this.setState({
                      muted: false,
                    });
                  }}
                >
                  <SoundButtonOff />
                </div>
              ) : (
                <div
                  className="svg-button"
                  onClick={(e) => {
                    e.preventDefault();
                    const videoDom = this.state.videoRef.current;
                    videoDom.muted = true;
                    this.setState({
                      muted: true,
                    });
                  }}
                >
                  <SoundButtonOn />
                </div>
              )}
            </div>
          </div>
          <div className="video-percentage-wrapper">
            <div
              className="video-percentage"
              ref={this.state.percentageDom}
            ></div>
          </div>
        </div>
      </>
    );
  }
}
export function inArticleLightCallback() {
  document.querySelector('.creative-placement-holder').style.display = 'none';
}

export function inArticleCallback(videoRef, paragraphRefs, afterParagraph) {
  document.querySelector('.creative-placement-holder').style.display = 'none';
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    let displayAnchorVisible = false;
    const displayAdAfterAnchor = new IntersectionObserver(([e]) => {
      displayAnchorVisible = e.intersectionRatio > 0 ? true : false;
      const wrapperDOM = document.querySelector('#creative-overlay-in-article');
      if (displayAnchorVisible && videoRef.current.paused && wrapperDOM) {
        videoRef.current.play();
        wrapperDOM.classList.remove('creative-overlay-in-article-hide');
        wrapperDOM.classList.add('creative-overlay-in-article-show');
      }
    });
    displayAdAfterAnchor.observe(paragraphRefs[afterParagraph].current);
  }
}

export class InArticleLight extends Component {
  render() {
    return (
      <div
        id="creative-overlay-in-article"
        className="clickable"
        style={{ backgroundColor: 'white' }}
      >
        <div className="in-article-light-wrapper">
          <div className="in-article-light-top">
            {' '}
            <a
              href="https://projectagora.com/"
              target="_blank"
              rel="noreferrer"
              className="project-agora-link-gray"
            >
              {' '}
              Project Agora Advertising{' '}
            </a>{' '}
          </div>
          <div>
            <div className="image-button-top">
              <div className="image-button-top-right">
                <div
                  className="svg-button"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .querySelector('#creative-overlay-in-article')
                      .remove();
                  }}
                >
                  <CloseButton />
                </div>
              </div>
            </div>
            <img
              src={inArticleLightImage}
              className="in-article-light-image"
              alt="in article light"
              onClick={(e) => {
                window.open('https://projectagora.com/', '_blank', 'noopener');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
