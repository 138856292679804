import { Component } from 'react';

import socialCardsVideo from './assets/videos/social-cards-video.mp4';

import leicaIcon from './assets/images/leica.png';
import iconHeart from './assets/images/icon-heart.png';
import iconComment from './assets/images/icon-comment.png';
import iconShare from './assets/images/icon-share.png';
import iconFlag from './assets/images/icon-flag.png';
import iconCamera from './assets/images/icon-camera.png';

import './SocialCards.css';

export class SocialCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoRef: props.creativeRef,
    };
  }

  componentDidMount() {
    document.querySelector('.creative-placement-holder').style.display = 'none';
    document.querySelector('#creative-overlay-social-cards').style.top = '0px';

    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      let topVisible = false,
        bottomVisible = false,
        midVisible = false;
      const videoDom = this.state.videoRef.current;
      videoDom.onended = function () {
        videoDom.currentTime = 0;
      };
      const creativeTopAnchor = document.querySelector('#creative-top-anchor');
      const creativeBottomAnchor = document.querySelector(
        '#creative-bottom-anchor',
      );
      const setDisplay = () => {
        if (
          topVisible === true ||
          midVisible === true ||
          bottomVisible === true
        ) {
          if (videoDom.paused) {
            videoDom.play();
          }
        }
      };
      const topAnchorObserver = new IntersectionObserver(([e]) => {
        topVisible = e.intersectionRatio > 0 ? true : false;
        setDisplay();
      });
      topAnchorObserver.observe(creativeTopAnchor);

      const creativeMidAnchor = document.querySelector('#creative-mid-anchor');
      const midAnchorObserver = new IntersectionObserver(([e]) => {
        midVisible = e.intersectionRatio > 0 ? true : false;
        setDisplay();
      });
      midAnchorObserver.observe(creativeMidAnchor);

      const bottomAnchorObserver = new IntersectionObserver(([e]) => {
        bottomVisible = e.intersectionRatio > 0 ? true : false;
        setDisplay();
      });
      bottomAnchorObserver.observe(creativeBottomAnchor);
    }
  }

  render() {
    return (
      <div id="creative-overlay-social-cards">
        <div className="social-cards-top">
          {' '}
          <a href="https://projectagora.com/" target="_blank" rel="noreferrer">
            {' '}
            Project Agora Advertising{' '}
          </a>{' '}
        </div>
        <div className="social-cards-instagram-card">
          <div className="social-cards-instagram-card-header">
            <img
              src={leicaIcon}
              className="social-cards-icon1"
              alt="leica icon"
            />
            <b> leica_camera </b>
            <img
              src={iconCamera}
              className="social-cards-icon2"
              alt="camera icon"
            />
          </div>
          <video muted className="social-cards-video" ref={this.state.videoRef}>
            <source src={socialCardsVideo} />
          </video>
          <hr />
          <div className="social-cards-text">
            <img src={iconHeart} alt="heart icon" />
            <img src={iconComment} alt="comment icon" />
            <img src={iconShare} alt="share icon" />
            <img src={iconFlag} className="icon-right" alt="flag icon" />
            <p>
              We are celebrating 10 years of excellence! <br />
              On the occasion of the tenth anniversary of the launch of the
              Leica S medium format system, we now annouce the latest camera
              model Leica S3, available from spring 2019. Discover the Leica
              S-System via link in bio.
            </p>
            <div className="hashtags-leica">
              <a
                href="https://www.instagram.com/explore/tags/leica_camera"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                #LeicaCamera{' '}
              </a>
              <a
                href="https://www.instagram.com/explore/tags/leica"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                #Leica{' '}
              </a>
            </div>
            <div className="agora-social-ancor-container">
            <a href="https://projectagora.com/gallery/?mvt=instagram-media&amp;mvn=1238aefef40a4eb68b601bb3789c52ea&amp;mvp=NA-PA_G-11239468&amp;mvl=Demo%20Sites%20-%20Single%20Creative%20Layout" 
               target="_blank" 
               rel="noreferrer"
            >
               {' '}
               Discover it now{' '}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
